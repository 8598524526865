@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Sarabun', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
}

.check {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ::-webkit-scrollbar {
  display: none !important;
} */

/* .recharts-surface {
  display: none !important;
}
.recharts-legend-icon {
  display: none !important;
} */

.recharts-legend-item {
  display: none !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #19943c !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #19943c !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
}

.css-1rhbuit-multiValue {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  /* Neutral/90 */
  color: #424242;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: rgb(97, 97, 97);
  border-radius: 8px !important;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 62px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-26l3qy-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 0;
  background-color: #fff;
  z-index: 10000000000000000;
  width: 100%;
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
}

.react-datepicker__input-container {
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(97, 97, 97);
  outline: none;
}

.react-datepicker__input-container input {
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.css-b62m3t-container {
  width: 100%;
}

.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-menu-submenu-arrow {
  color: #19943c !important;
}
.submenu-title:hover {
  color: #19943c !important;
}
.ant-menu-submenu
  .ant-menu-submenu-inline
  .ant-menu-submenu-open
  .ant-menu-submenu-selected {
  color: #19943c;
}
.MainAppMenu .ant-menu-submenu-selected {
  color: #19943c;
}
.MainAppMenu .ant-menu-item-selected,
.MainAppMenu .ant-menu-item-selected a,
.MainAppMenu .ant-menu-item-selected a:hover {
  color: #19943c;
}
.ant-menu-vertical {
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ant-menu-item:hover {
  color: #19943c;
}

/* .ant-menu-vertical .ant-menu-submenu-selected {
	color: white;
} */
.ant-menu-vertical .ant-menu-item-icon {
  width: 26px;
  height: 26px;
}

/* .ant-menu-sub.ant-menu-inline {
	background: #19943c;
} */

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-vertical .ant-menu-item a {
  color: black;
}

.ant-menu-vertical .ant-menu-item-selected a {
  color: #ebfff3;
}

.ant-menu-submenu-vertical {
  margin: 12px 0;
}

.drop {
  top: 30px !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: black;
}

/* .ant-menu-inline .ant-menu-item:after {
} */

.MainAppMenu .ant-menu-inline .ant-menu-item:after,
.MainAppMenu .ant-menu-vertical-left .ant-menu-item:after,
.MainAppMenu .ant-menu-vertical-right .ant-menu-item:after,
.MainAppMenu .ant-menu-vertical .ant-menu-item:after {
  border-left: 3px solid #19943c;
  border-right: none;
  background: #ebfff3 !important;
  opacity: 0.3;
  left: 0;
}

.ant-menu-title-content:hover {
  color: #19943c;
}

.ant-menu-item .ant-menu-title-content a:hover {
  color: #19943c;
}

.PhoneInput {
  height: 60px;
  width: 100%;
  border: 1px solid #616161;
  padding-left: 5px;
  border-radius: 8px;
  outline: none;
}
.PhoneInputCountry {
}
.PhoneInputInput {
  font-size: 16px;
  outline: none;
  border: none;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #19943c !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #19943c;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #19943c;
}

input[type='checkbox'] {
  accent-color: #19943c;
}

.selectOptions
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
}

.selectOptions
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 54px;
  background-color: #ffffff;
  padding: 11px 16px;
  position: relative;
}

.tab-header .ant-tabs-tab {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.75) !important;
}

.tab-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #19943c !important;
}

.tab-header .ant-tabs-tab:hover {
  color: #19943c !important;
}

.tab-header .ant-tabs-ink-bar {
  background: #19943c !important;
}

.tab-header .ant-tabs-nav {
  margin: 0 !important;
  padding-left: 10px;
}

.tab-header .ant-tabs-tab {
  font-size: 14px;
}

.ant-input-number-input {
  height: 54px !important;
}

/* .ant-input {
	height: 54px !important;
} */
