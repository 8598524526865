.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 36px;
  margin: 0 auto;
}

.wrapperh2 {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #19943c;
}
.custom-btn {
  background-color: #1b943d !important;
  border-color: #1b943d !important;
  border-radius: 8;
  border: none;
}
.custom-btn:hover {
  background-color: #30c35a !important;
  border-color: #30c35a !important;
}
.wrapperp {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4b492b;
  margin-bottom: 35px;
}

.wrapperbox {
  padding: 25px 33px;
  width: 539px;
  background: #ffffff;
  border-radius: 8px;
}

.submitHandler {
  padding: 16px 134px;
  width: 100%;
  height: 56px;
  background: #19943c;
  border-radius: 4px;
  outline: none;
  border: none;
  color: white;
  &:hover {
    background: #19943c;
    opacity: 0.75;
  }
  &:disabled {
    opacity: 0.5;
    background: #19943c;
  }
}

.flexInput {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  height: 54px;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
}

.businessNameh4 {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #070602;
  margin-bottom: 28px;
}

.businessNameBox {
  padding: 14px 13px;
  height: 69px;
  background: #ffffff;
  border: 1px solid rgba(43, 149, 88, 0.2);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  color: #020201;
  cursor: pointer;
}

.boxright {
  flex: 1;

  h3 {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  p {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
}

.boxleft {
  margin-right: 13px;
}

.formSelect {
  width: 100%;
  padding: 15px 10px;
  border-radius: 3%;
  border-color: rgba(128, 128, 128, 0.638);
}
